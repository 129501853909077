import * as _ from 'lodash-es';
import { valuesetSource } from 'cosmos-config/generator';

import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from 'cosmos-config/utils';

const buildId = (entry) => {
  if (entry.resourceKey != null) {
    return entry.resourceKey.id;
  }

  if (entry.workflowInstanceKey != null) {
    return entry.workflowInstanceKey.id;
  }

  if (entry.masterdataKey != null) {
    return entry.masterdataKey.id;
  }

  if (entry.id != null) {
    return entry.id;
  }

  if (entry.pid != null) {
    return entry.pid;
  }

  return null;
};

export const buildDocument = (entry) => {
  const resource = _.chain(entry.properties)
    .keyBy('name')
    .mapValues('value')
    .value();
  return {
    id: buildId(entry),
    ...resource,
  };
};

const buildProperties = (document, prepareFunction = {}) => {
  return Object.entries(document).map(([name, value]) => {
    const prepare = prepareFunction[name] || ((v) => v);
    return {
      name,
      value: prepare(value),
    };
  });
};

export const buildFilters = (documents, propertyNames = []) => {
  const filters = documents.reduce((acc, cur) => {
    Object.entries(cur).forEach(([key, value]) => {
      if (!propertyNames.includes(key)) {
        return acc;
      }

      if (acc[key] == null) {
        acc[key] = [];
      }

      const pushIntoAcc = (v) => {
        if (!acc[key].includes(v)) {
          acc[key].push(v);
        }
      };

      if (value != null && value !== '') {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            pushIntoAcc(v);
          });
        } else {
          pushIntoAcc(value);
        }
      }

      return null;
    });

    return acc;
  }, {});

  return Object.entries(filters).reduce((acc, [key, options]) => {
    acc[key] = options.sort().map((o) => {
      return {
        label: o,
        value: o,
      };
    });

    return acc;
  }, {});
};

const propertiesToPropertyMap = (properties, propertyName) => {
  return properties
    .filter((p) => p.name != null)
    .map((p) => ({
      [p.name]: (...args) => {
        const func = p[propertyName];
        return func.call(p, ...args);
      },
    }))
    .reduce(
      (acc, cur) => ({
        ...acc,
        ...cur,
      }),
      {}
    );
};

export const getParseFunctions = (properties) =>
  propertiesToPropertyMap(properties, 'parseValue');
export const getPrepareFunction = (properties) =>
  propertiesToPropertyMap(properties, 'prepareValue');

export const createValuesetMap = (propertiesMap, valuesetsMap) =>
  propertiesMap.reduce((acc, cur) => {
    const vs = valuesetsMap[cur.valuesetName];

    if (Array.isArray(vs)) {
      return {
        ...acc,
        [cur.name]: vs,
      };
    }

    // start:  I don't like this but whatever.
    if (
      vs == null &&
      cur.valuesetSource === valuesetSource.STATIC_VALUESET &&
      Array.isArray(cur.options)
    ) {
      return {
        ...acc,
        [cur.name]: cur.options,
      };
    }
    // end;

    return acc;
  }, {});

export const initiateResource = (item, properties = {}) => {
  const parseFunctions = getParseFunctions(properties);
  return Object.entries(parseFunctions).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: item[key] || value(null),
    }),
    {}
  );
};

export const applyDefaultValues = (resource, properties, userdata) => {
  const defaultValues = _.chain(properties)
    .keyBy('name')
    .mapValues((p) =>
      _.isFunction(p.getDefaultValue) ? p.getDefaultValue(userdata) : null
    )
    .omitBy(_.isNil)
    .toPairs()
    .reduce((acc, [propertyName, defaultValue]) => {
      _.set(acc, propertyName, defaultValue);
      return acc;
    }, {})
    .value();

  const { displayname, ...rest } = defaultValues;
  if (displayname != null) {
    return { ...rest, ...resource, displayname };
  }

  return { ...defaultValues, ...resource };
};

export const prepareResource = (
  item,
  propertiesMap,
  initial = false,
  userdata
) => {
  const editableProperties = propertiesMap
    .filter((p) => (p.editable && p.updatable) || p.systemUpdatable)
    .map((p) => p.name);

  const prepareFunctions = getPrepareFunction(propertiesMap);
  return buildProperties(
    initial ? applyDefaultValues(item, propertiesMap, userdata) : item,
    prepareFunctions
  ).filter((p) => editableProperties.includes(p.name));
};

export const prepareResourceForGateway = (
  resource,
  properties,
  initial = false,
  userdata
) => {
  const editableProperties = properties
    .filter((p) => (p.editable && p.updatable) || p.systemUpdatable)
    .map((p) => p.name);

  const resourceDefaults = initial
    ? applyDefaultValues(resource, properties, userdata)
    : resource;

  const correctedResource = resourceCorrectionForGateway(
    resourceDefaults,
    properties
  );

  // const propertiesTypeMap = _(properties)
  //   .keyBy('name')
  //   .mapValues((property) => {
  //     if (property.type === propertyType.DATE) {
  //       return 'date';
  //     }

  //     if (property.handleAsMulti || property.multiple) {
  //       return 'array';
  //     }

  //     return 'string';
  //   })
  //   .value();

  return _.map(correctedResource, (value, propertyName) => ({
    name: propertyName,
    value,
    // type: propertiesTypeMap[propertyName],
  })).filter((p) => editableProperties.includes(p.name));
};

export const parseGatewayResource = (item, propertiesMap) => {
  const resource = _.chain(item.properties)
    .mapKeys('name')
    .mapValues('value')
    .value();
  return resourceCorrectionOfGateway(
    { id: buildId(item), ...resource },
    propertiesMap
  );
};

export const parseGatewayResources = (items, propertiesMap) => {
  return items.map((d) => parseGatewayResource(d, propertiesMap));
};

export const isResourceStub = (resource, includeUrls = false) => {
  if (includeUrls && resource.targeturl !== null) return true;
  return (
    resource.resourcetype === 2 &&
    !resource.contentitemcount &&
    resource.targeturl === null
  );
};

export const valuesCorrection = (resource, propertiesMap) => {
  return propertiesMap.reduce((acc, cur) => {
    const value = acc[cur.name];
    const predefinedValue = cur.getPredefinedValue(resource);
    // const defaultValue = cur.defaultValue;

    return {
      ...acc,
      [cur.name]: predefinedValue != null ? predefinedValue : value,
    };
  }, resource);
};
