/* eslint-disable func-names */
import {
  put,
  call,
  select,
  take,
  fork,
  join,
  spawn,
  all,
  takeEvery,
  takeLatest,
  getContext,
  cancel,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import * as _ from 'lodash-es';
import { flatMapEmails, getFileIdentifier, getI18n } from 'cosmos-core';
import { uuidv4 } from 'cosmos-config/utils';

import {
  clearRepositoryQuery,
  resetOrderBy,
  selectDocuments,
  highlightDocs,
} from '../../Actions/repository';
import * as actions from '../../Actions/types';
import {
  addUploadedDocumentId,
  clearUploadData,
  updateUploadProgress,
  setUploadCheckLoading,
  setUploadFiles,
  setUploadInProgress,
  setUploadLoading,
  setUploadProgress,
  skipMetadataAssignment,
  startMetadataAssignment,
  updateUploadFileMeta,
  setIsUnzipping,
  addFilesToUpload,
  updateUploadStats,
  updateFileHash,
} from '../../Actions/upload';
import { notify } from '../../Actions/ui';
import {
  getComputedFilter,
  getSelectedFolder,
} from '../../Selectors/repository';
import { getUserdata } from '../../Selectors/userdata';
import {
  getUploadErrors,
  getUploadFiles,
  getUploadOptions,
} from '../../Selectors/upload';
import {
  parseGatewayResources,
  prepareResourceForGateway,
} from '../../Utils/documentUtils';
import {
  blobToFileInfo,
  flatMapArchives,
  trimFileExtension,
} from '../../Utils/fileUtils';
import { toQueryProperties } from '../../Utils/projectUtils';
import { generateDocumentId, parseResourceId } from '../../Utils';
import callApi from '../Effects/callApi';
import callBatch from '../Effects/callBatch';
import complete from '../Effects/complete';
import repositoryApi from '../../Api/repository';
import documentApi from '../../Api/document';
import { uploadErrorType } from '../../Constants/uploadErrorType';
import { asyncProgressStatusType } from '../../Constants/asyncProgressStatusType';
import duplicatesCheckerSaga from './duplicatesCheckerSaga';
import { updateDocuments } from './documentSelectionSaga';
import {
  deleteDocuments,
  generateGeneralPreset,
  resourceToProperties,
} from '.';
import { createContentHashSagas } from './contentHashSaga';
import callSequence from '../Effects/callSequence';

function* checkFileIsNotEmpty(files) {
  yield put(
    setUploadCheckLoading(true, { message: 'Checking document(s) sizes.' })
  );

  try {
    const emptyFiles = files.filter((file) => file.size === 0);

    yield all(
      emptyFiles.map((f) => put(notify(`Document ${f.name} is empty`, 'error')))
    );

    return files.filter((file) => file.size !== 0);
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setUploadCheckLoading(false));
  }
}

function* metadataAssignmentFlow(documentIds) {
  const { type: mtaType } = yield take([
    actions.upload.START_METADATA_ASSIGNMENT,
    actions.upload.SKIP_METADATA_ASSIGNMENT,
  ]);

  if (mtaType === actions.upload.START_METADATA_ASSIGNMENT) {
    yield put(setUploadInProgress(true));

    const action = yield take([
      actions.upload.UPLOAD_PROCESS_SUCCESS,
      actions.upload.UPLOAD_PROCESS_CANCEL,
    ]);

    const { type } = action;
    if (type === actions.upload.UPLOAD_PROCESS_CANCEL) {
      const deletedDocumentIds = yield call(deleteDocuments, documentIds, true);

      yield complete(action, deletedDocumentIds);
    }

    yield put(setUploadInProgress(false));
  }
}

function* progressListener(chan) {
  while (true) {
    const action = yield take(chan);
    yield put(action);
  }
}

function* generateDocumentProperties(fileName, mask = {}) {
  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);

  const selectedFolder = yield select(getSelectedFolder);

  const userdata = yield select(getUserdata);

  let properties = prepareResourceForGateway(
    {
      displayname: trimFileExtension(fileName),
      ...mask,
      filename: fileName,
    },
    propertiesMap,
    true,
    userdata
  );

  if (selectedFolder != null) {
    const filter = yield select(getComputedFilter);

    const preset = {
      ...selectedFolder,
      ..._.mapValues(filter, (v) => (Array.isArray(v) ? _.first(v) : v)),
    };

    const editableProperties = propertiesMap
      .filter((p) => p.editable && p.usePreset && p.updatable)
      .map((p) => p.name);

    const folderProperties = prepareResourceForGateway(preset, propertiesMap);
    const copyOverProperties = folderProperties.filter(
      (p) =>
        editableProperties.includes(p.name) &&
        p.value != null &&
        (!Array.isArray(p.value) || p.value.length > 0)
    );

    return [...properties, ...copyOverProperties];
  }

  return properties;
}

const withProgress = (saga) =>
  call(function* () {
    const uploadingUUID = uuidv4();
    const uploadProgressChannel = yield channel();
    const uploadProgressTask = yield fork(
      progressListener,
      uploadProgressChannel
    );

    const returnValue = yield call(saga, (progress) => {
      uploadProgressChannel.put(updateUploadProgress(uploadingUUID, progress));
    });

    uploadProgressTask.cancel();

    return returnValue;
  });

function* getResourceDefaultProperties(
  fileName,
  files,
  confidential = false,
  mask = {}
) {
  const annexName = files.length === 1 ? files[0].path : '';

  return yield generateDocumentProperties(fileName, {
    ...mask,
    accesslevel: confidential ? 3 : 10,
    accesslevelexternal: confidential ? 3 : 10,
    // always persist AnnexName
    AnnexName: annexName,
  });
}

function* fetchDocumentsByIds(folderId, documentIds) {
  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(properties);

  try {
    const items = yield call(
      repositoryApi.getDocumentsByIds,
      folderId,
      documentIds,
      requestedProps
    );
    const documents = parseGatewayResources(items, properties);
    return documents;
  } catch (err) {
    console.error(err);
  }

  return [];
}

function* createDocumentsAndUploadFiles(folderId, files, uploadOptions = {}) {
  yield put(setUploadLoading(true, 'Uploading files into repository.'));

  function* uploadDocument(file) {
    const {
      file: data,
      confidential,
      placeholder,
      reference,
      refresourceid,
      relatedTo,
      relationType,
      ...mask
    } = file;

    try {
      let documentId = null;

      const properties = yield getResourceDefaultProperties(
        file.name,
        placeholder || reference ? [] : _.compact([data]),
        confidential,
        mask
      );

      if (reference) {
        documentId = yield callApi(
          repositoryApi.createReference,
          folderId,
          refresourceid,
          properties
        );
      } else if (relatedTo != null) {
        documentId = yield withProgress(function* (progressCallback) {
          return yield callApi(
            repositoryApi.createRelatedDocument,
            relatedTo,
            relationType,
            [data],
            properties,
            progressCallback
          );
        });
      } else {
        documentId = yield withProgress(function* (progressCallback) {
          return yield callApi(
            repositoryApi.createDocument,
            folderId,
            placeholder ? [] : [data],
            properties,
            progressCallback
          );
        });
      }

      yield put(addUploadedDocumentId(documentId));
      return documentId;
    } catch (err) {
      console.error(err);
      yield put(
        notify(
          `Error while uploading file ${file.name}. ${err.message}.`,
          'error'
        )
      );
      return null;
    }
  }

  function* uploadFileSet(filesSet, parentFileName) {
    let ParentEmailId = null;

    if (parentFileName != null) {
      const parentEmail = filesSet.find((f) => f.name === parentFileName);
      if (parentEmail != null) {
        ParentEmailId = yield call(uploadDocument, parentEmail);
      }
    }

    const allDocumentsUploadSagas = filesSet
      .filter((f) => f.name !== parentFileName)
      .map((f) =>
        call(uploadDocument, {
          ...f,
          ParentEmailId,
        })
      );

    function* executeUpload() {
      if (uploadOptions?.serialUpload) {
        return yield callSequence(allDocumentsUploadSagas);
      } else {
        return yield callBatch(10, allDocumentsUploadSagas);
      }
    }

    const newDocumentIds = yield call(executeUpload);

    return [ParentEmailId, ...newDocumentIds].filter((id) => id != null);
  }

  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);
  const preset = yield call(generateGeneralPreset, propertiesMap);

  let documentIds = [];

  if (uploadOptions?.multiContent) {
    try {
      const properties = yield getResourceDefaultProperties(
        _.get(files, '[0].name', ''),
        files
      );
      const documentId = yield withProgress(function* (progressCallback) {
        return yield callApi(
          repositoryApi.createDocument,
          folderId,
          _.map(files, 'file'),
          properties,
          progressCallback
        );
      });
      documentIds = [documentId];
    } catch (err) {
      console.error(err);
      yield put(
        notify(
          `Error while uploading files into multicontent document. ${err.message}.`,
          'error'
        )
      );
    }
  } else {
    const groupedFiles = _.groupBy(files, 'parentFileName');
    for (let [groupName, attachments] of Object.entries(groupedFiles)) {
      const filesetDocumentIds = yield call(
        uploadFileSet,
        attachments,
        groupName === 'undefined' ? null : groupName
      );

      documentIds = [...documentIds, ...filesetDocumentIds];
    }
  }

  documentIds = documentIds.filter((id) => id != null);

  try {
    const documents = yield call(fetchDocumentsByIds, folderId, documentIds);
    const documentsWithPresets = documents.map((d) => ({
      ...d,
      ...preset,
    }));
    yield put(selectDocuments(documentsWithPresets));
  } catch (err) {
    console.error(err);
  }

  yield put(setUploadLoading(false));
  return documentIds;
}

function* importFlow(folderId, skipUploadWizard = false) {
  while (true) {
    const { files, callback } = yield take(actions.repository.IMPORT_FILES);
    const documentIds = yield call(
      createDocumentsAndUploadFiles,
      folderId,
      files
    );
    const metadataAssignment = yield fork(metadataAssignmentFlow, documentIds);

    yield spawn(callback);

    if (skipUploadWizard) {
      yield put(skipMetadataAssignment());
    } else {
      yield put(startMetadataAssignment());
    }

    yield join(metadataAssignment);
    yield put(clearRepositoryQuery());
    yield put(resetOrderBy());
  }
}

function* uploadSaga(folderId, skipUploadWizard = false) {
  let uploadProgress = {};

  yield takeEvery(
    actions.upload.UPDATE_UPLOAD_PROGRESS,
    function* ({ progress, uuid }) {
      uploadProgress = { ...uploadProgress, [uuid]: progress };
      const totalProgress = Object.values(uploadProgress).reduce(
        (total, current) => total + current,
        0
      );
      yield put(setUploadProgress(totalProgress));
    }
  );

  yield fork(importFlow, folderId, skipUploadWizard);

  let fileBlobs = [];

  yield takeLatest(actions.upload.UPDATE_DOCUMENT_CONTENT, function* (action) {
    const { fileMatches } = action;
    let fileMatchesWithBlobs = [];

    for (const fM of fileMatches) {
      const fileBlob = fileBlobs.find(
        (fB) => getFileIdentifier(fB) === fM.fileIdentifier
      );
      fileMatchesWithBlobs.push({
        documentId: fM.documentId,
        file: fileBlob,
      });

      yield put(
        updateUploadStats(fM.fileIdentifier, {
          fileName: fileBlob.name,
          filePath: fileBlob.path || '',
          progress: 0,
          loaded: 0,
          status: asyncProgressStatusType.PENDING,
        })
      );
    }

    const updateDocumentContentSagas = yield call(
      _createUpdateDocumentContentSagas,
      fileMatchesWithBlobs
    );

    yield callBatch(5, updateDocumentContentSagas);
  });

  yield takeLatest(actions.upload.UPLOAD_FILES_NEW, function* (action) {
    const { fileIdentifiers, callback } = action;

    // initialize upload stats
    for (const fileIdentifier of fileIdentifiers) {
      const fileBlob = fileBlobs.find(
        (fB) => getFileIdentifier(fB) === fileIdentifier
      );
      yield put(
        updateUploadStats(fileIdentifier, {
          fileName: fileBlob.name,
          filePath: fileBlob.path || '',
          progress: 0,
          loaded: 0,
          status: asyncProgressStatusType.PENDING,
        })
      );
    }

    const newFileUploadSagas = yield call(
      _createNewFileUploadSagas,
      folderId,
      fileIdentifiers.map((fI) =>
        fileBlobs.find((fB) => getFileIdentifier(fB) === fI)
      )
    );

    const newDocumentIds = yield callBatch(5, newFileUploadSagas);
    yield spawn(callback, newDocumentIds);
  });

  yield takeEvery(actions.upload.CLEAR_UPLOAD_DATA, () => {
    fileBlobs = [];
    uploadProgress = {};
  });

  while (true) {
    const action = yield take([
      actions.upload.ADD_FILES_TO_UPLOAD,
      actions.upload.UNZIP_FILES_TO_UPLOAD,
      actions.upload.ADD_PLACEHOLDER_TO_UPLOAD,
      actions.upload.UPLOAD_FILES,
      actions.upload.REMOVE_FILES_FROM_UPLOAD,
      actions.upload.UPDATE_UPLOAD_FILE_ORDER,
      actions.upload.UPDATE_FILE_CONTENT,
    ]);

    const { type, instant, ...actionArgs } = action;

    if (type === actions.upload.ADD_FILES_TO_UPLOAD) {
      try {
        const { files: allFiles, options } = actionArgs;

        const { files: allFilesAttachments, relationMap } = yield call(
          flatMapEmails,
          allFiles
        );

        const nonEmptyFiles = yield call(
          checkFileIsNotEmpty,
          allFilesAttachments
        );

        fileBlobs = _.unionBy(fileBlobs, nonEmptyFiles, getFileIdentifier);

        const files = fileBlobs.map(blobToFileInfo);
        yield put(setUploadFiles(files));

        for (const [fileName, parentFileName] of Object.entries(relationMap)) {
          yield put(
            updateUploadFileMeta(fileName, {
              parentFileName,
            })
          );
        }

        if (options.shouldCheckDuplicates) {
          const currentFileHashMap = yield select(
            (state) => state.upload.fileHashMap
          );

          // generate hashes for new files
          const newFiles = fileBlobs.filter(
            (f) =>
              !Object.keys(currentFileHashMap).some(
                (k) => k === getFileIdentifier(f)
              )
          );

          const hashes = yield call(
            createContentHashSagas,
            newFiles,
            function* (generatedHashCount) {
              yield put(
                setUploadCheckLoading(true, {
                  loadingStats: {
                    fileContentHashGeneratedCount: generatedHashCount,
                    totalFilesCount: files.length,
                  },
                })
              );
            }
          );

          for (const h of hashes) {
            const { fileIdentifier } = h;
            currentFileHashMap[fileIdentifier] = h;
            yield put(updateFileHash(fileIdentifier, h));
          }

          yield call(duplicatesCheckerSaga, currentFileHashMap);
        }

        if (options.meta != null) {
          yield put(updateUploadFileMeta(null, options.meta));
        }
      } catch (error) {
        console.error(error);
        yield put(
          notify(
            getI18n().t('upload.error_add_files_to_upload', {
              defaultValue:
                'Something went wrong while adding your files to the upload',
            }),
            'error'
          )
        );
      }
    }

    if (type === actions.upload.UNZIP_FILES_TO_UPLOAD) {
      const { options } = actionArgs;
      yield put(setIsUnzipping(true));
      fileBlobs = yield call(flatMapArchives, fileBlobs);
      yield put(setIsUnzipping(false));
      // fileBlobs = fileBlobs.filter(
      //   (fB) => !archiveContentTypes.includes(fB.type)
      // );
      yield put(
        addFilesToUpload(fileBlobs, {
          shouldCheckDuplicates: options.shouldCheckDuplicates,
        })
      );
    }

    if (type === actions.upload.ADD_PLACEHOLDER_TO_UPLOAD) {
      const { fileName, meta } = actionArgs;

      const placeholderFile = new File([''], fileName, {
        type: 'text/plain',
      });

      fileBlobs = _.unionBy(fileBlobs, [placeholderFile], 'name');

      yield put(setUploadFiles(fileBlobs.map(blobToFileInfo)));

      yield put(
        updateUploadFileMeta(fileName, {
          displayname: 'Not Applicable',
          ...meta,
          placeholder: true,
        })
      );
    }

    if (type === actions.upload.REMOVE_FILES_FROM_UPLOAD) {
      const { filesToRemove } = actionArgs;

      filesToRemove.forEach((ftR) => {
        fileBlobs = fileBlobs.filter(
          (file) => ftR.fileIdentifier !== getFileIdentifier(file)
        );
      });

      yield put(setUploadFiles(fileBlobs.map(blobToFileInfo)));
    }

    const uploadErrors = yield select(getUploadErrors);

    if (type === actions.upload.UPDATE_FILE_CONTENT) {
      const { filesToUpdate, callback } = actionArgs;

      yield put(setUploadLoading(true));

      const uploadProgressChannel = yield channel();
      const contentResults = [];

      for (const mId of filesToUpdate.matchIds) {
        const matchingBlobs = filesToUpdate.matches
          .get(mId)
          // going from matchedFileIds, because the order of the files should be preserved
          .matchedFileIds.map((mFId) =>
            fileBlobs.find((f) => getFileIdentifier(f) === mFId)
          );

        const fileMatch = filesToUpdate.matches.get(mId);

        // const startMillis = DateTime.now().toMillis();

        const uploadProgressTask = yield fork(function* (chan) {
          while (true) {
            const action = yield take(chan);
            const { loaded } = action.payload;

            yield put(
              updateUploadStats(mId, {
                fileName: filesToUpdate.matches.get(mId).stub.displayname,
                progress: Math.round(
                  (loaded /
                    matchingBlobs.reduce(
                      (acc, curr) => acc.size + curr.size,
                      0
                    )) *
                    100
                ),
                // startMillis,
                // time: DateTime.now().toMillis() - startMillis,
              })
            );
          }
        }, uploadProgressChannel);

        let error;
        try {
          yield callApi(
            documentApi.uploadDocumentContentItems,
            mId,
            matchingBlobs,
            [],
            (loaded) => {
              uploadProgressChannel.put({
                type: 'upload/setProgress',
                payload: { loaded },
              });
            }
          );
        } catch (err) {
          console.error(err);
          error = err;
        }

        yield cancel(uploadProgressTask);

        // const endTime = DateTime.now().toMillis();
        const result = {
          id: mId,
          errorMessage: error?.message,
          displayName: fileMatch.stub.displayname,
          fileName:
            matchingBlobs.length > 1 ? 'Multiple files' : matchingBlobs[0].name,
          filePath: matchingBlobs.length > 1 ? '' : matchingBlobs[0].path || '',
          complete: true,
          progress: 100,
          // time: endTime - startMillis,
          // startMillis,
        };

        yield put(updateUploadStats(mId, result));
        contentResults.push(result);
      }

      const updatedMeta = filesToUpdate.matchIds
        // do not update metadata of elements with errors
        .filter((mId) => {
          return !contentResults.some(
            (cR) => cR.id === mId && cR.errorMessage?.length
          );
        })
        .map((mId) => {
          const newMeta = {
            ...filesToUpdate.matches.get(mId).stub,
            displayname: trimFileExtension(
              fileBlobs.find(
                (f) =>
                  getFileIdentifier(f) ===
                  filesToUpdate.matches.get(mId).matchedFileIds[0]
              ).name
            ),
            filename: fileBlobs.find(
              (f) =>
                getFileIdentifier(f) ===
                filesToUpdate.matches.get(mId).matchedFileIds[0]
            ).name,
            AnnexName:
              fileBlobs.find(
                (f) =>
                  getFileIdentifier(f) ===
                  filesToUpdate.matches.get(mId).matchedFileIds[0]
              ).path || '',
          };

          delete newMeta['HashValue'];

          return newMeta;
        });

      try {
        const updatedIdentifiers = yield call(updateDocuments, updatedMeta);
        yield spawn(callback, updatedIdentifiers);
      } catch (err) {
        console.error(err);
        yield put(
          notify('Something went wrong while updating some files content')
        );
        yield spawn(callback([]));
      } finally {
        yield put(setUploadLoading(false));
      }
    }

    const combinedUploadErrors = Object.values(uploadErrors).flat();

    const actualUploadErrors = combinedUploadErrors.filter(
      (eeD) =>
        eeD.type === uploadErrorType.DOC_CONTENT_EXISTS ||
        eeD.type === uploadErrorType.FILE_CONTENT_DUPLICATE
    );
    if (
      (type === actions.upload.UPLOAD_FILES || instant) &&
      fileBlobs.length > 0 &&
      !actualUploadErrors.length
    ) {
      const uploadOptions = yield select(getUploadOptions);

      const { uploadWizard } = actionArgs;

      const skipMetadata = !uploadWizard || skipUploadWizard;

      const files = yield select(getUploadFiles);
      const filesMap = _.chain(files)
        .keyBy((f) => `${f.name}_${f.path || ''}`)
        .value();

      const filesToUpload = _.chain(fileBlobs)
        .map((file) => {
          const fileMeta = filesMap[`${file.name}_${file.path || ''}`];

          if (fileMeta != null) {
            if (fileMeta.reference) {
              const ed = uploadErrors.existingDocuments.find(
                (e) => e.name === file.name && e.path === file.path
              );
              return {
                ...fileMeta,
                valid: skipMetadata,
                refresourceid: generateDocumentId('RWE', ed.identifier),
              };
            }

            return {
              ...fileMeta,
              valid: skipMetadata,
              file,
            };
          }

          return {
            name: file.name,
            valid: skipMetadata,
            file,
          };
        })
        .sortBy('order')
        .value();

      const documentIds = yield call(
        createDocumentsAndUploadFiles,
        folderId,
        filesToUpload,
        uploadOptions
      );

      if (documentIds.length > 0) {
        // if (autoLink && !multiContent) {
        //   try {
        //     yield put(setUploadLoading(true, 'Linking uploaded documents.'));

        //     yield callApi(resourceApi.linkDocuments, documentIds);
        //   } catch (err) {
        //     console.error(err);
        //     yield notify(
        //       `Failed to automatically link documents. ${err.message}`,
        //       'user-error'
        //     );
        //   } finally {
        //     yield put(setUploadLoading(false));
        //   }
        // }

        yield put(highlightDocs(documentIds));

        const documentIdsNoReferences = documentIds.filter((id) => {
          const { resourceType } = parseResourceId(id);
          return resourceType === 2;
        });

        const metadataAssignment = yield fork(
          metadataAssignmentFlow,
          documentIdsNoReferences
        );

        const skipMA = skipMetadata; // || documentIdsNoReferences.length === 0;

        if (skipMA) {
          yield put(skipMetadataAssignment());
        }

        yield complete(action, documentIdsNoReferences);

        yield join(metadataAssignment);

        if (!skipMA) {
          yield put(clearRepositoryQuery());
        }

        yield put(clearUploadData());
        yield put(resetOrderBy());
      }
    }
  }
}

// eslint-disable-next-line require-yield
function* _createUpdateDocumentContentSagas(fileMatches) {
  return fileMatches.map(function* (fileMatch) {
    yield call(_updateDocumentContentSaga, fileMatch);
  });
}

// eslint-disable-next-line require-yield
function* _createNewFileUploadSagas(resourceId, files) {
  return files.map(function* (file) {
    const newResourceIds = yield call(_newFileUploadSaga, resourceId, file);
    return newResourceIds;
  });
}

function* _updateDocumentContentSaga(fileMatch) {
  const { documentId, file } = fileMatch;
  const fileIdentifier = getFileIdentifier(file);
  let statResults = {
    fileName: file.name,
    progress: 0,
    loaded: 0,
    status: asyncProgressStatusType.PENDING,
  };

  const uploadProgressChannel = yield channel();

  const uploadProgressTask = yield fork(function* (chan) {
    while (true) {
      const progressAction = yield take(chan);
      const { loaded } = progressAction.payload;

      const progress = Math.round((loaded / file.size) * 100);

      statResults = {
        ...statResults,
        progress,
        loaded: Math.round(loaded),
        status:
          progress >= 100
            ? asyncProgressStatusType.SUCCESS
            : asyncProgressStatusType.LOADING,
      };
      yield put(updateUploadStats(fileIdentifier, statResults));
    }
  }, uploadProgressChannel);

  try {
    yield callApi(
      documentApi.uploadDocumentContentItems,
      documentId,
      [file],
      [],
      (loaded) => {
        uploadProgressChannel.put({
          type: 'upload/setProgress',
          payload: { loaded },
        });
      }
    );
  } catch (err) {
    console.error(err);
    statResults.error = err;
    statResults.status = asyncProgressStatusType.ERROR;
  }

  yield put(updateUploadStats(fileIdentifier, statResults));

  yield cancel(uploadProgressTask);
}

function* _newFileUploadSaga(resourceId, file) {
  const fileIdentifier = getFileIdentifier(file);
  let statResults = {
    fileName: file.name,
    progress: 0,
    loaded: 0,
    status: asyncProgressStatusType.PENDING,
  };

  const uploadProgressChannel = yield channel();
  const uploadProgressTask = yield fork(function* (chan) {
    while (true) {
      const action = yield take(chan);
      const { loaded } = action.payload;

      const progress = Math.round((loaded / file.size) * 100);

      statResults = {
        ...statResults,
        progress,
        loaded: Math.round(loaded),
        status:
          progress >= 100
            ? asyncProgressStatusType.SUCCESS
            : asyncProgressStatusType.LOADING,
      };
      yield put(updateUploadStats(fileIdentifier, statResults));
    }
  }, uploadProgressChannel);

  let newDocumentId = '';

  try {
    const projectService = yield getContext('projectService');
    const propertiesMap = yield call(projectService.getProperties);
    const docareaService = yield getContext('docareaService');
    const valuesetsMap = yield select(docareaService.getValuesetsMap);

    const properties = resourceToProperties(
      {
        displayname: trimFileExtension(file.name),
        filename: file.name,
      },
      propertiesMap,
      valuesetsMap
    );

    newDocumentId = yield callApi(
      repositoryApi.createDocument,
      resourceId,
      [file],
      properties,
      (loaded) =>
        uploadProgressChannel.put({
          type: 'upload/setProgress',
          payload: { loaded },
        })
    );
  } catch (err) {
    console.error(err);
    statResults.error = err;
    statResults.status = asyncProgressStatusType.ERROR;
  }

  yield put(updateUploadStats(fileIdentifier, statResults));

  yield cancel(uploadProgressTask);

  return newDocumentId;
}

export default uploadSaga;
